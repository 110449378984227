import { customElement } from "solid-element";
import { createMemo, createResource, createSignal } from "solid-js";

customElement(
    "builderall-global-script", {token: ""}, props => {
        const sourceURL = createMemo(() => {
            if (!props.token) return "";
            return `https://bgs.kub.app.builderall.io/api/get-script-by-token/${props.token}`;
        });

        const [source] = createResource(sourceURL, async sourceURL => {
            if (!sourceURL) return "";
            const res = await fetch(sourceURL, {
                headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                },
            });
            const {data: {script}} = await res.json();
            return script;
        });

        const [iconCode, setIconCode] = createSignal("f5ff");

        let textareaRef: HTMLTextAreaElement | undefined;
        
        function copyScript() {
            if (textareaRef) {
                textareaRef.select();
                navigator.clipboard.writeText(textareaRef.value)
                    .then(() => {
                        setIconCode("ad00");

                        setTimeout(() => {
                            setIconCode("f5ff");
                        }, 2000);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }
        }

    return(
        <>
            <style>{require("bundle-text:./styles/index.scss")}</style>
            <div class="content__textarea">
                <textarea 
                    class="textarea" 
                    rows={4}
                    value={source()} 
                    readOnly ref={el => 
                    textareaRef = el} 
                />
                <button class="button__copy" onclick={copyScript}>
                    <builderall-icon code={iconCode()} />
                </button>
            </div>
        </>
    )
})