import {tools} from "./metadata";
import {proxyURL, sendRequest} from "../../services/office-api";
import {render} from "solid-js/web";
import FacebookCommunityModal from "./elements/FacebookCommunityModal";
import SendMessageToSponsorModal from "./elements/SendMessageToSponsorModal";
import LegacyToolsModal from "./elements/LegacyToolsModal";
import HeaderLeft from "./elements/HeaderLeft";
import HeaderRight from "./elements/HeaderRight";
import {getTranslations} from "../../services/translation";
import GetStartedModal from "./elements/GetStartedModal";
import {setTranslations, translations} from "../../services/context";

type Resources = {
    accessRules: Set<string>,
    userQuotas: Set<string>,
    history: Array<string>,
    whiteLabel: { title: string, logo: string } | false,
    user: { id: number, name: string, email: string, lang: string }
    translations: { [_: string]: string },
    hasNotifications: Boolean,
    whiteLabelData: Array<string>,
}
type ToggleModal = {
    setToggleModal: Boolean,
}
customElements.define("builderall-appshell", class extends HTMLElement {
    private preparedStatement? = null;

    constructor() {
        super();

        this.attachShadow({mode: "open"});

        (new MutationObserver(() => {
            this.querySelectorAll("builderall-banner, builderall-help, builderall-footer, builderall-enom, builderall-digital-file-repository, builderall-global-script")
                .forEach(child => {
                    child.setAttribute("tool", this.getAttribute("tool"));
                    child.setAttribute("token", this.getAttribute("token"));
                });
        })).observe(this, {childList: true, subtree: true});
    }

    static get observedAttributes() {
        return ["token", "tool"];
    }

    attributeChangedCallback() {
        if (this.preparedStatement) clearTimeout(this.preparedStatement);

        this.preparedStatement = setTimeout(async () => {
            this.updateChildComponents();

            const cachedResources = this.fetchResourcesFromCache();
            this.mountAll(cachedResources);
            setTranslations(cachedResources.translations);

            if (!this.getAttribute("token")) return;

            const fetchedResources = await this.fetchResources();
            this.mountAll(fetchedResources);
            setTranslations(fetchedResources.translations);

            this.saveResourcesToCache(fetchedResources);
        });
    }


    async fetchResources(): Promise<Resources> {
        const [accessRules, history, whiteLabel, user, hasNotifications, userQuotas, whiteLabelData] = await Promise.all([
            this.getAccessRules(),
            this.syncHistory(),
            this.getWhiteLabel(),
            this.getUser(),
            this.hasNotifications(),
            this.getUserQuotas(),
            this.getWhiteLabelData()
        ]);

        const translations = await getTranslations(user.lang);

        return {accessRules, history, whiteLabel, user, translations, hasNotifications, userQuotas, whiteLabelData};
    }

    fetchResourcesFromCache(): Resources {
        const defaultResources: Resources = {
            accessRules: new Set<string>(),
            userQuotas: new Set<string>(),
            history: new Array<string>(),
            whiteLabel: false,
            user: {
                id: 0,
                name: "",
                email: "",
                lang: "",
            },
            translations: {},
            hasNotifications: false,
            whiteLabelData: new Set<string>()
        };

        const cached = localStorage.getItem("builderall-appshell--resources");
        if (!cached) return defaultResources;

        try {
            const {history, accessRules, whiteLabel, user, translations, hasNotifications, userQuotas, whiteLabelData} = JSON.parse(cached);
            return {history, accessRules: new Set(accessRules), whiteLabel, user, translations, hasNotifications, userQuotas, whiteLabelData};
        } catch (e) {
            console.warn("[appshell] unable to parse cached resources", e);
            return defaultResources;
        }
    }

    saveResourcesToCache({history, accessRules, whiteLabel, user, translations, hasNotifications, userQuotas, whiteLabelData}: Resources) {
        localStorage.setItem("builderall-appshell--resources", JSON.stringify({
            history, whiteLabel, user, translations, hasNotifications,
            accessRules: Array.from(accessRules),
            userQuotas: Array.from(userQuotas),
            whiteLabelData: Array.from(whiteLabelData),
        }));
    }

    mountAll(resources: Resources) {
        try {
            this.shadowRoot.innerHTML = require("bundle-text:./templates/index.html");

            render(() => HeaderLeft({
                token: this.getAttribute("token"),
                whiteLabel: resources.whiteLabel,
            }), this.shadowRoot.getElementById("header-left"));

            render(() => HeaderRight({
                token: this.getAttribute("token"),
                email: resources.user.email,
                name: resources.user.name
            }), this.shadowRoot.getElementById("header-right"));

            this.mountHeaderDropdown(resources);
            this.mountHeaderTooltip(resources);
            this.mountSidebarElements(resources);
            this.mountAvatarElements(resources);
            this.mountModals(resources);
            this.mountHistory(resources);
            this.mountHeaderMessage(resources);
            this.defineAddEventListenerToHeaderRight();
        } catch (e) {
            console.error("[appshell] mount error", e);
        } finally {
            if (document.readyState === "complete") {
                this.defaultActions();
            } else {
                window.addEventListener("load", () => {
                    this.defaultActions();
                });
            }
        }
    }

    updateChildComponents() {
        this.querySelectorAll("builderall-banner, builderall-help, builderall-footer, builderall-enom, builderall-digital-file-repository, builderall-global-script")
            .forEach(child => {
                child.setAttribute("tool", this.getAttribute("tool"));
                child.setAttribute("token", this.getAttribute("token"));
            });
    }

    async mountHeaderMessage({accessRules, translations}: Resources){
        // const alerts = await this.sendRequest(`wrapper/alerts`);

        if(accessRules.has(`general-stuff|pay-now`) || accessRules.has(`general-stuff|unpause-account`)){
            const headerMessageElement = this.shadowRoot!.querySelector<HTMLElement>(".header-message");
            headerMessageElement!.style.display = 'inline-block';
            const quotasUrl = process.env.OFFICE+'/us/office/edit-my-account?tab=quotas';

            const messagesGeneralStuff = [
                {
                    slug: 'pay-now',
                    p: translations["header-menu--pay-now--message"] || '',
                    a: translations["header-menu--pay-now--title"] || 'Pay Now',
                    href: this.proxyURL("general-stuff|pay-now"),
                    type: 'ERROR'
                },
                {
                    slug: 'unpause-account',
                    p: translations["header-menu--unpause-account--message"] || '',
                    a: translations["header-menu--unpause-account--title"] || 'Unpause',
                    href: this.proxyURL("general-stuff|unpause-account"),
                    type: 'ERROR'
                },
                // ...alerts
            ];

            for(let i = 0; i < messagesGeneralStuff.length; i++){
                if(accessRules.has(`general-stuff|${messagesGeneralStuff[i].slug}`)){
                    const newEl1 = document.createElement('div');
                    newEl1.className = `header-message-item ${messagesGeneralStuff[i].slug}`;
                    let newP1 = document.createElement('p');
                    let newLink1 = document.createElement('a');
                    newP1.innerHTML = messagesGeneralStuff[i].p;
                    newLink1.innerHTML = messagesGeneralStuff[i].a;
                    newLink1.href = messagesGeneralStuff[i].href;
                    newEl1.appendChild(newP1);
                    newEl1.appendChild(newLink1);
                    headerMessageElement.appendChild(newEl1);
                } else if(messagesGeneralStuff[i].slug == 'billing-quotas-80-percent'){
                    const newEl1 = document.createElement('div');
                    newEl1.className = `header-message-item ${messagesGeneralStuff[i].type} ${messagesGeneralStuff[i].slug}`;
                    let newP1 = document.createElement('p');
                    let newLink1 = document.createElement('a');
                    newP1.innerHTML = translations["appshell-quotas-80-alert"] || '';
                    newLink1.innerHTML = translations["appshell-view-usage"] || '';
                    newLink1.href = quotasUrl;
                    newEl1.appendChild(newP1);
                    newEl1.appendChild(newLink1);
                    headerMessageElement.appendChild(newEl1);
                } else if(messagesGeneralStuff[i].slug == 'billing-quotas-100-percent'){
                    const newEl1 = document.createElement('div');
                    // newEl1.className = `header-message-item ${messagesGeneralStuff[i].type}`;
                    newEl1.className = `header-message-item ERROR ${messagesGeneralStuff[i].slug}`;
                    let newP1 = document.createElement('p');
                    let newLink1 = document.createElement('a');
                    newP1.innerHTML = translations["appshell-quotas-100-alert"] || '';
                    newLink1.innerHTML = translations["appshell-click-here-preview-bill"] || '';
                    newLink1.href = quotasUrl;
                    newEl1.appendChild(newP1);
                    newEl1.appendChild(newLink1);
                    headerMessageElement.appendChild(newEl1);
                }
            }

            // Mount carroussel
            const alertMessages = headerMessageElement.querySelectorAll('.header-message-item');
            const profileAlert = this.shadowRoot!.querySelector<HTMLElement>(".ba-alert-profile");
            const buttonsBox = headerMessageElement.querySelector('.header-buttons');
            buttonsBox.innerHTML = "";
            var currentAlert = 0;

            if(alertMessages.length > 0){
                // Mount carroussel buttons
                let carrousselButtons = null;
                let carrousselTimer = null;
                if(alertMessages.length > 1){
                    for(let i = 0; i < alertMessages.length; i++){
                        let newBtn = document.createElement('button');
                        newBtn.className = `btn-carroussel-message ${currentAlert == i ? 'active-btn-message' : ''}`;
                        buttonsBox.appendChild(newBtn);
                    }
                    carrousselButtons = buttonsBox.querySelectorAll('.btn-carroussel-message');
                    carrousselTimer = setInterval(showAlerts, 5000);
                }

                showAlerts();

                function showAlerts(data){
                    if(!data){
                        currentAlert++;
                        if(currentAlert == alertMessages.length || currentAlert > alertMessages.length){
                            currentAlert = 0;
                        }
                    }

                    for(let i = 0; i < alertMessages.length; i++){
                        if(currentAlert == i){
                            if(alertMessages.length > 1){
                                carrousselButtons[i].className = 'btn-carroussel-message active-btn-message';
                                alertMessages[i].style.display = 'flex';
                            }
                            // Profile alerts
                            profileAlert.style.display = 'none';
                            if(alertMessages[i].className.indexOf('billing-quotas-80-percent') != -1){
                                profileAlert.style.display = 'grid';
                                profileAlert.className = 'ba-alert-profile ba-alert ba-warning';
                                profileAlert.querySelector('span').innerHTML = translations["appshell-quotas-80-alert"] || '';
                                let linkEl = profileAlert.querySelector('a');
                                linkEl.innerHTML = translations["appshell-view-usage"] || '';
                                linkEl.href = quotasUrl;
                            } else if(alertMessages[i].className.indexOf('billing-quotas-100-percent') != -1){
                                profileAlert.style.display = 'grid';
                                profileAlert.className = 'ba-alert-profile ba-alert ba-error';
                                profileAlert.querySelector('span').innerHTML = translations["appshell-quotas-100-alert"] || '';
                                let linkEl = profileAlert.querySelector('a');
                                linkEl.innerHTML = translations["appshell-click-here-preview-bill"] || '';
                                linkEl.href = quotasUrl;
                            }
                        } else {
                            carrousselButtons[i].className = 'btn-carroussel-message';
                            alertMessages[i].style.display = 'none';
                        }
                    }
                }

                // Change carroussell active element
                for(let i = 0; i < carrousselButtons.length; i++){
                    carrousselButtons[i].addEventListener("click", () => {
                        currentAlert = i;
                        showAlerts(true);
                        clearInterval(carrousselTimer);
                        carrousselTimer = setInterval(showAlerts, 5000);
                        carrousselButtons[i].className = 'btn-carroussel-message active-btn-message';
                        for(let y = 0; y < carrousselButtons.length; y++){
                            if(currentAlert != y){
                                carrousselButtons[y].className = 'btn-carroussel-message';
                            }
                        }
                    });
                };
                
                this.shadowRoot.querySelector<HTMLDivElement>("#dropdown-avatar")
                    .style.maxHeight = "calc(100vh - 85px - 44px)"
    
                setTimeout(() => document.querySelector(".ba-app-content").classList.add("has-pay-now"), 1e3)
                setTimeout(() => document.querySelector(".ba-app-content").classList.add("has-header-message"), 1e3)
            }
        }
    }

    defaultActions(): void {
        const mainSidebarElement = this.shadowRoot!.querySelector<HTMLElement>(".main-sidebar");
        const wrapperHeaderElement = this.shadowRoot!.querySelector(".wrapper-header");
        const baAppContentElement = () => document.querySelector<HTMLElement>(".ba-app-content") || this.shadowRoot.getElementById("main-content");

        if (baAppContentElement()) {
            baAppContentElement()!.style.overflowY = "auto";
            baAppContentElement()!.style.transition = "filter 0.15s linear";
        }

        // z-index according access wrapper or maint content
        function moreZIndex5() {
            baAppContentElement()!.style.zIndex = "5";
        }

        function lessZIndex5() {
            baAppContentElement()!.style.zIndex = "-5";
        }

        wrapperHeaderElement?.addEventListener("mouseenter", () => {
            lessZIndex5();
        });

        wrapperHeaderElement?.addEventListener("mouseleave", () => {
            let timer = setTimeout(() => {
                moreZIndex5();
            }, 200);
            wrapperHeaderElement.addEventListener("mouseenter", () => {
                clearTimeout(timer);
            });
            mainSidebarElement?.addEventListener("mouseenter", () => {
                clearTimeout(timer);
            });
        });

        mainSidebarElement?.addEventListener("mouseenter", () => {
            lessZIndex5();
        });

        mainSidebarElement?.addEventListener("mouseleave", () => {
            let timer = setTimeout(() => {
                moreZIndex5();
            }, 200);
            mainSidebarElement.addEventListener("mouseenter", () => {
                clearTimeout(timer);
            });
            wrapperHeaderElement?.addEventListener("mouseenter", () => {
                clearTimeout(timer);
            });
        });
    }

    showOrHideOverlay(data: String) {
        const baElement = () => document.querySelector<HTMLElement>(".ba-app-content") || this.shadowRoot.getElementById("main-content");

        const wrapperHeaderElement = this.shadowRoot?.querySelector(".wrapper-header");

        if (data == "show") {
            baElement()!.style.filter = "brightness(0.5)";
            wrapperHeaderElement?.classList.remove("show-shadow");
            return;
        }

        baElement()!.style.filter = "none";
        wrapperHeaderElement?.classList.add("show-shadow");
    }

    mountModals(resources: Resources) {
        render(() => FacebookCommunityModal({
            communities: [
                "builderall-br-affiliate-community",
                "builderall-br-users-community",
                "builderall-es-community",
                "builderall-es-affiliate-community",
                "builderall-us-official",
                "builderall-us-affiliate-community",
                "builderall-us-community",
                "builderall-de-community",
                "builderall-de-affiliate-community",
                "builderall-fr-community",
                "builderall-it-viral-team",
            ]
                .filter(slug => resources.accessRules.has(`facebook|${slug}`))
                .map(slug => ({
                    title: resources.translations[`top-menu--connect--community--facebook--${slug}--title`],
                    description: resources.translations[`top-menu--connect--community--facebook--${slug}--description`],
                    href: this.proxyURL(`facebook|${slug}`),
                })),
        }), this.shadowRoot.getElementById("facebook-community-modal"));

        const tools = this.getLegacyTools(resources);

        if(Object.keys(tools).length > 0){
            render(() => LegacyToolsModal({tools}), this.shadowRoot.getElementById("legacy-tools-modal"));
        } else {
            this.shadowRoot.querySelector<HTMLButtonElement>(".dropdown-main-content-legacy-tools").style.display = 'none';
        }

        render(() => SendMessageToSponsorModal({tools}), this.shadowRoot.getElementById("send-message-to-spondor-modal"));

        this.defineAddEventListenerToModals();
    }

    defineAddEventListenerToModals() {
        const modalLegacyTools = this.shadowRoot!.getElementById("legacy-tools-modal");
        const modalFacebookCommunity = this.shadowRoot!.getElementById("facebook-community-modal");
        const modalSendMessageToSponsor = this.shadowRoot!.getElementById("send-message-to-spondor-modal");
        const sendMessageToSponsorbtn = this.shadowRoot!.querySelector<HTMLButtonElement>('#btn-close-modal-enom');
        const sendMessageToSponsorTextarea = this.shadowRoot!.querySelector<HTMLButtonElement>('.modal-content-textarea');
        const sendMessageToSpondorRequest = this.shadowRoot!.querySelector<HTMLButtonElement>('.send-message-to-sponsor-btn');

        function inView(data, e) {
            return !data!.contains(e.target) && data?.style.display == "flex";
        }

        function closeModal(data) {
            data!.style.display = "none";
        }

        document.addEventListener("mouseup", (e) => {
            // Close modals
            if (inView(modalLegacyTools, e)) {
                closeModal(modalLegacyTools);
            } else if (inView(modalFacebookCommunity, e)) {
                closeModal(modalFacebookCommunity);
            }
            
        });
        modalSendMessageToSponsor.addEventListener("mouseup", (e) => {
            if (e?.target?.id == 'send-message-to-spondor-modal' ){
                closeModal(modalSendMessageToSponsor);
                reseParams()
            }   
        });
        sendMessageToSponsorbtn.addEventListener("mouseup", (e) => {
                closeModal(modalSendMessageToSponsor);
                reseParams()
     });    
     function reseParams() {
        sendMessageToSponsorTextarea.value = ""
        sendMessageToSpondorRequest.classList.remove("ba-disabled")
        sendMessageToSpondorRequest.disabled = false
     }
    }

    getLegacyTools({accessRules, translations}: Resources) {
        return [
            "pixel-perfect",
            "webinar-1",
            "canvas",
            "video-wrapper-1",
            "ecommerce-magento",
            "roulette-1",
            "mobile-first",
            "instagram-autoresponder-1",
            "facebook-inbox-answer",
            "videotag-1",
            "wordpress-builder-1",
            "app-builder",
            "responsive-blog",
            "presentation-studio",
            "floating-videos",
            "heat-map",
            "responsive-builder",
            "3d-photo-editor-studio",
            "omb-reviews",
            "directory-builder",
            "delivery",
            "video-funnel-builder",
            "market-place",
            "chat-builder",
            "design-resources",
            "funnel-club",
            "viral-referrals",
            "worckie",
            "seo-report",
            "social-autopost",
            "website-agency",
            "branding-designs",
            "clickmap",
            "helpdesk",
        ]
            .filter(slug => accessRules.has(`legacy|${slug}`))
            .map(slug => ({
                title: translations[`top-menu--tools--legacy--${slug}--title`],
                href: this.proxyURL(`legacy|${slug}`),
                target: "_blank",
            }));
    }

    mountHeaderDropdown(resources: Resources) {
        const dropdownTemplate = require("bundle-text:./templates/dropdown-header-center.html");
        const headerDropdownItems = this.getHeaderDropdownItems(resources);

        headerDropdownItems.forEach(it => {
            const li = document.createElement("li");

            if (it.type == "link") {
                let newLinkElement = document.createElement("a");
                newLinkElement.href = it.link;
                newLinkElement.target = it.target;
                newLinkElement.innerHTML = it.element;
                newLinkElement.className = "header-center-link";
                li.appendChild(newLinkElement);
            } else {
                li.innerHTML = dropdownTemplate;
                const dropdownToolsElement = li.querySelector<HTMLElement>(".dropdown.tools");
                const dropdownSectionsElement = li.querySelector<HTMLElement>(".dropdown.sections");
                // Set title to menu
                li.querySelector(".btn-title")!.textContent = it.element;

                // Set new value to hidden input with title and description about main element
                (<HTMLInputElement>li.querySelector(".header-center-hidden-input")).value = JSON.stringify([it.element, it.description]);

                /* Dropdown */
                if (it.type == "normal") {
                    li.querySelector<HTMLElement>(".dropdown.normal")!.classList.add("show");
                    // Set left content to dropdown
                    this.resetDropdownData(li);

                    const mainContent = li.querySelector(".dropdown-main-content");

                    it.data.forEach(d => {
                        let newMainDiv = document.createElement("div");
                        newMainDiv.className = "dropdown-main-content-main-div";

                        // Main content in dropdown with icon
                        let newDiv = document.createElement("div");
                        let newSpan = document.createElement("span");
                        let newP = document.createElement("p");

                        newDiv.className = "dropdown-main-content-title-column-div";
                        newSpan.innerHTML = d.icon;
                        newP.textContent = d.title;

                        newDiv.appendChild(newSpan);
                        newDiv.appendChild(newP);
                        newMainDiv.appendChild(newDiv);

                        // Elements in dropdown
                        const newUl = document.createElement("ul");
                        let hasShowFalse = false;
                        d.data.forEach(e => {
                            let newLi = document.createElement("li");
                            let newA1 = document.createElement("a");
                            let newA2 = document.createElement("a");
                            let newHiddenInput = document.createElement("input");

                            newA1.href = e.href;
                            newA1.textContent = e.title;
                            newA1.target = e.target;
                            newA1.className = "link-tool";

                            if (e.locked) {
                                const lock = document.createElement("i");
                                lock.className = "access-rules-lock";
                                lock.innerHTML = require("bundle-text:./assets/lock.svg");
                                lock.title = "Unavailable, requires plan upgrade";
                                newA1.append(lock);
                            }

                            newA2.href = e.href;
                            newA2.innerHTML = require("bundle-text:./assets/new-window.svg");
                            newA2.target = "_blank";
                            newA2.className = "link-blank-tool";

                            newHiddenInput.type = "hidden";
                            newHiddenInput.className = "input-dropdown-element-data";
                            newHiddenInput.value = JSON.stringify([e.title, e.description]);

                            if (!e.show) {
                                newLi.className = "li-dont-show";
                                hasShowFalse = true;
                            }

                            newLi.appendChild(newHiddenInput);
                            newLi.appendChild(newA1);
                            newLi.appendChild(newA2);
                            newUl.appendChild(newLi);
                            newMainDiv.appendChild(newUl);
                        });

                        // Show All Tools Button or Retract and Legacy button
                        let newBottomDiv = document.createElement("div");
                        newBottomDiv.className = "dropdown-main-content-bottom";

                        if (hasShowFalse || Object.entries(d).length > 5) {
                            let newButtonAllTools = document.createElement("button");
                            newButtonAllTools.textContent = hasShowFalse ? "See more" : Object.entries(d).length > 5 ? "See less" : "";
                            newButtonAllTools.className = "dropdown-main-content-btn all-tools";
                            newBottomDiv.appendChild(newButtonAllTools);
                        } else {
                            let newSpan = document.createElement("span");
                            newBottomDiv.appendChild(newSpan);
                        }

                        newMainDiv.appendChild(newBottomDiv);
                        mainContent?.appendChild(newMainDiv);
                    });
                } else if (it.type == "tools"){

                    dropdownToolsElement?.classList.add("show");
                    let mainContent = document.createElement("div");
                    mainContent.className = "dropdown-main-content";

                    it.data.forEach(d => {
                        let newMainDiv = document.createElement("div");
                        newMainDiv.className = "dropdown-main-content-main-div";

                        // Main content in dropdown with icon
                        let newDiv = document.createElement("div");
                        let newSpan = document.createElement("span");
                        let newP = document.createElement("p");

                        newDiv.className = "dropdown-main-content-title-column-div";
                        newP.textContent = d.title;

                        // show lock if don't have access
                        let unlockLink = document.createElement("a");
                        if(d.showUnlock){
                            newSpan.innerHTML = require("bundle-text:./assets/lock.svg");
                            newDiv.appendChild(newSpan);
                            newMainDiv.classList.add('locked');

                            // unlock link
                            unlockLink.href = this.proxyURL(d.slug);
                            unlockLink.target = '_blank';
                            unlockLink.className = 'ba-btn ba-md ba-blue unlock-link';
                            unlockLink.textContent = resources.translations['appshell-unlock']+' '+d.title;
                        }

                        newDiv.appendChild(newP);
                        newMainDiv.appendChild(newDiv);

                        // User Quotas
                        let quotasDiv = document.createElement("div");
                        let emailSpan = document.createElement("span");
                        let subSpan = document.createElement("span");
                        let newHR = document.createElement("hr");

                        quotasDiv.className = "user-quotas";

                        let emailSent = '0'
                        let subscribers = '0'
                        if (!this.isEmpty(d.userQuotas)) {
                            emailSent = d.userQuotas['mailingboss-emails-sent']
                            subscribers = d.userQuotas['mailingboss-subscribers']
                        }
                        emailSpan.textContent = emailSent + ' ' + resources.translations['sent-emails-mo'];
                        subSpan.textContent = subscribers + ' ' + resources.translations['avatar-subscribers'];

                        quotasDiv.appendChild(subSpan);
                        quotasDiv.appendChild(emailSpan);
                        newMainDiv.appendChild(quotasDiv);
                        newMainDiv.appendChild(newHR);

                        // Elements in dropdown
                        const newUl = document.createElement("ul");

                        d.data.forEach(e => {
                            let newLi = document.createElement("li");
                            let newA1 = document.createElement("a");
                            let newA2 = document.createElement("a");
                            let newHiddenInput = document.createElement("input");

                            newA1.href = e.href;
                            newA1.textContent = e.title;
                            newA1.target = e.target;
                            newA1.className = "link-tool";

                            if (e.locked) {
                                const lock = document.createElement("i");
                                lock.className = "access-rules-lock";
                                lock.innerHTML = require("bundle-text:./assets/lock.svg");
                                lock.title = "Unavailable, requires plan upgrade";
                                newA1.append(lock);
                            }

                            newA2.href = e.href;
                            newA2.innerHTML = require("bundle-text:./assets/new-window.svg");
                            newA2.target = "_blank";
                            newA2.className = "link-blank-tool";

                            newHiddenInput.type = "hidden";
                            newHiddenInput.className = "input-dropdown-element-data";

                            newHiddenInput.value = JSON.stringify([e.title, e.description, e.slug.split("|")[1]]);

                            newLi.appendChild(newHiddenInput);
                            newLi.appendChild(newA1);
                            newLi.appendChild(newA2);
                            newUl.appendChild(newLi);
                            newMainDiv.appendChild(newUl);
                        });
                        
                        newMainDiv?.appendChild(unlockLink);
                        mainContent?.appendChild(newMainDiv);

                        // Show All Tools Button or Retract and Legacy button
                        let newBottomDiv = document.createElement("div");
                        newBottomDiv.className = "dropdown-main-content-bottom";
    
                        if (d.slug == "general-stuff|unlock-premium") {
                            let newButtonLegacyTools = document.createElement("button");
                            newButtonLegacyTools.className = "dropdown-main-content-legacy-tools";
                            newButtonLegacyTools.textContent = "Legacy";
                            newBottomDiv.appendChild(newButtonLegacyTools);
                        }
    
                        newMainDiv.appendChild(newBottomDiv);
                    });

                    /*
                    // right content
                    let rightContent = document.createElement("div");
                    rightContent.className = 'dropdown-right-content';

                    let newH2 = document.createElement("h2");
                    newH2.className = 'dropdown-right-content-title';

                    let newImg = document.createElement("img");
                    newImg.className = 'dropdown-right-content-img';

                    let newP = document.createElement("p");
                    newP.className = 'dropdown-right-content-description';

                    rightContent.appendChild(newH2);
                    rightContent.appendChild(newImg);
                    rightContent.appendChild(newP);

                    let hrLine = document.createElement("hr")
                    hrLine.className = 'dropdown-hr';
                     */

                    dropdownToolsElement?.appendChild(mainContent);
                    /*
                    dropdownToolsElement?.appendChild(hrLine);
                    dropdownToolsElement?.appendChild(rightContent);
                     */
                } else {
                    dropdownSectionsElement?.classList.add("show");

                    for (let i = 0; i < it.data.length; i++) {
                        let newMainDiv = document.createElement("div");
                        let newH2 = document.createElement("h2");
                        let newSpan = document.createElement("span");
                        let newHr = document.createElement("hr");

                        newMainDiv.className = "dropdown-sections-content";
                        newH2.textContent = it.data[i].title;
                        newH2.className = "dropdown-sections-title";
                        newSpan.innerHTML = (it.data[i] as any).description;
                        newSpan.className = "dropdown-sections-description";
                        newHr.className = "dropdown-hr";

                        newMainDiv.appendChild(newH2);
                        newMainDiv.appendChild(newSpan);
                        let newDiv = document.createElement("div");
                        newDiv.className = "dropdown-sections-content-elements";
                        if (it.data[i].slug == "connect_community") {
                            newDiv.classList.add("connect-community");
                        } else if (it.data[i].slug == "connect_real_time_support") {
                            newDiv.classList.add("connect-real-time-support");
                        } else if (it.data[i].slug == "connect_help") {
                            newDiv.classList.add("connect-help");
                        }

                        it.data[i].data.forEach(e => {
                            let newLink = document.createElement("a");
                            if (e.modal) {
                                newLink.className = "open-facebook-community-modal";
                            } else {
                                newLink.href = e.href;
                                newLink.target = e.target;
                            }

                            if (e.icon) {
                                let newIconSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");

                                newIconSvg.setAttribute("width", "40px");
                                newIconSvg.setAttribute("height", "40px");

                                newIconSvg.innerHTML = e.icon;
                                newLink.appendChild(newIconSvg);
                            }
                            if (e.title) {
                                let newTitleSpan = document.createElement("span");
                                newTitleSpan.textContent = e.title;
                                newLink.appendChild(newTitleSpan);
                            }

                            newDiv.append(newLink);
                            newMainDiv.appendChild(newDiv);
                        });
                        if (i != 0) {
                            dropdownSectionsElement?.appendChild(newHr);
                        }
                        dropdownSectionsElement?.appendChild(newMainDiv);
                    }
                }
            }

            // Actions
            this.defineAddEventListenerToHeaderDropdown(li);
            this.shadowRoot?.getElementById("header-center-ul")!.appendChild(li);
        });
    }

    defineAddEventListenerToHeaderDropdown(element: HTMLElement) {
        const headerCenterElement = this.shadowRoot?.querySelector(".header-center");
        const headerCenterBtnElement = element.querySelector(".header-center-btn");
        const headerCenterLinkElement = element.querySelector(".header-center-link");
        const dropdownMainContentBtns = element.querySelectorAll(".dropdown-main-content-btn");
        const linkToolDropdownElement = element.querySelectorAll(".link-tool");
        const dropdownElement = element.querySelector<HTMLElement>(".dropdown.show");
        const dropdownMainContentLegacyToolsElement = element.querySelector(".dropdown-main-content-legacy-tools");
        const openFacebookCommunityModalElement = element.querySelector(".open-facebook-community-modal");
        const modalLegacyTools = this.shadowRoot!.getElementById("legacy-tools-modal");
        const modalFacebookCommunity = this.shadowRoot!.getElementById("facebook-community-modal");
        const mainSidebarElement = this.shadowRoot?.querySelector(".main-sidebar");
        const rm = this;

        // Show Dropdown
        function addActiveClass() {
            headerCenterBtnElement?.classList.add("active");
            rm.showOrHideOverlay("show");
        }

        function removeActiveClass(el) {
            if (el.classList.contains("active")) {
                el.classList.remove("active");
            }
        }

        function changeZIndex() {
            rm.shadowRoot?.querySelectorAll<HTMLElement>(".dropdown").forEach(el => el.style.zIndex = "-1");
        }

        headerCenterBtnElement?.addEventListener("mouseenter", () => {
            this.shadowRoot?.querySelectorAll<HTMLElement>(".dropdown").forEach(el => el.style.display = "none");
            this.resetDropdownData(element);
            if (dropdownElement) {
                dropdownElement.style.display = "flex";
            }
            // Add active class
            this.shadowRoot?.querySelectorAll<HTMLElement>(".header-center-btn").forEach(el => {
                // Remove active from all
                removeActiveClass(el);
            });
            addActiveClass();
            // Change z-index
            changeZIndex();
        });

        headerCenterLinkElement?.addEventListener("mouseenter", () => {
            this.shadowRoot?.querySelectorAll<HTMLElement>(".dropdown").forEach(el => el.style.display = "none");

            // Add active class
            this.shadowRoot?.querySelectorAll<HTMLElement>(".header-center-btn").forEach(el => {
                // Remove active from all
                removeActiveClass(el);
            });
            addActiveClass();
            // Change z-index
            changeZIndex();
        });

        // Hide Dropdown
        function closeDropdown() {
            if (dropdownElement) {
                dropdownElement.style.display = "none";
                // Doesn't remove overlay if mouse is inside sidbear
                if (!mainSidebarElement!.matches(":hover")) {
                    rm.showOrHideOverlay("hide");
                }
            }
        }

        element.querySelector(".dropdown")?.addEventListener("mouseleave", () => {
            let timer = setTimeout(() => {
                closeDropdown();
                this.resetDropdownData(element);
            }, 200);
            headerCenterElement?.addEventListener("mouseenter", () => {
                clearTimeout(timer);
            });
        });

        headerCenterElement?.addEventListener("mouseleave", () => {
            let timer = setTimeout(() => {
                closeDropdown();
                this.resetDropdownData(element);
            }, 200);
            headerCenterElement?.addEventListener("mouseenter", () => {
                clearTimeout(timer);
            });
            // Change z-index
            this.shadowRoot?.querySelectorAll<HTMLElement>(".dropdown").forEach(el => el.style.zIndex = "3");
        });

        // Show All Tools or Hide Tools
        dropdownMainContentBtns.forEach((el: any) => {
            el.addEventListener("click", () => {
                if (el.classList.contains("all-tools")) {
                    el.classList.remove("all-tools");
                    el.textContent = "See less";
                } else {
                    el.classList.add("all-tools");
                    el.textContent = "See more";
                }

                // Set tools with display block or none
                let ul = el.parentElement.previousElementSibling;
                ul.querySelectorAll(".li-dont-show").forEach((el: HTMLElement) => {
                    el.style.display = el.style.display == "flex" ? "none" : "flex";
                });
            });
        });

        // Show modal Legacy Tools
        dropdownMainContentLegacyToolsElement?.addEventListener("click", () => {
            modalLegacyTools!.style.display = "flex";
            modalLegacyTools!.style.backdropFilter = "brightness(0.5)";
        });

        // Show modal Facebook Community
        openFacebookCommunityModalElement?.addEventListener("click", () => {
            modalFacebookCommunity!.style.display = "flex";
            modalFacebookCommunity!.style.backdropFilter = "brightness(0.5)";
        });

        // Set new title and description in left content
        linkToolDropdownElement.forEach((el: any) => {
            el.addEventListener("mouseenter", () => {
                let data = JSON.parse(el.previousElementSibling.value);

                const titleEl = element.querySelector(".dropdown-left-content-title");
                titleEl!.textContent = data[0];

                const descEl = element.querySelector(".dropdown-left-content-description");
                descEl!.textContent = data[1];

                const titleRightEl = element.querySelector(".dropdown-right-content-title");
                titleRightEl!.textContent = data[0];

                const descRightEl = element.querySelector(".dropdown-right-content-description");
                descRightEl!.textContent = data[1];
                
                const imgRightEl = element.querySelector<HTMLImageElement>(".dropdown-right-content-img");
                imgRightEl!.src = !tools[data[2]]['img'] ? tools['website-builder']['img'] : tools[data[2]]['img'];
            });
        });
    }

    resetDropdownData(element: HTMLElement) {
        element.querySelector(".header-center-btn")?.classList.remove("active");

        const encoded = element.querySelector<HTMLInputElement>(".header-center-hidden-input")?.value;
        const [title, description] = encoded ? JSON.parse(encoded) : ["", ""];

        const titleElement = element.querySelector(".dropdown-left-content-title");
        if (titleElement) titleElement.textContent = title;

        const descriptionElement = element.querySelector(".dropdown-left-content-description");
        if (descriptionElement) descriptionElement.textContent = description;

        const titleRightElement = element.querySelector(".dropdown-right-content-title");
        if (titleRightElement) titleRightElement.textContent = title;

        const descriptionRightElement = element.querySelector(".dropdown-right-content-description");
        if (descriptionRightElement) descriptionRightElement.textContent = description;
    }

    getHeaderDropdownItems({accessRules, translations, userQuotas}: Resources) {
        const mapTool = (prefix: string, t: any, i: number) => {
            const slug = `${prefix}|${t.slug}`;
            const hasAccess = accessRules.has(slug);
            return {
                ...t,
                slug,
                title: translations[`top-menu--tools--${t.slug}--title`],
                description: translations[`top-menu--tools--${t.slug}--description`],
                locked: !hasAccess,
                href: hasAccess
                    ? this.proxyURL(slug)
                    : this.proxyURL("general-stuff|checkout-plans"),
                target: hasAccess ? "_self" : "_blank",
                show: i < 5,
            };
        };

        return [
            {
                id: 1,
                element: translations["top-menu--tools--name"],
                slug: "tools",
                type: "tools",
                left_title: translations["top-menu--tools--title"],
                description: translations["top-menu--tools--description"],
                data: [
                    {
                        title: translations["top-menu--tools--core--title"],
                        slug: "general-stuff|unlock-core",
                        showUnlock: accessRules.has("general-stuff|unlock-core"),
                        data: [
                            {
                                slug: "website-builder",
                            },
                            {
                                slug: "browser-notification",
                            },
                            {
                                slug: "dns-manager", //top-menu--tools--dns-manager
                            },
                            {
                                slug: "professional-email",
                            },
                            {
                                slug: "url-shortener", // top-menu--tools--url-shortene
                            },
                        ].map((t, i) => mapTool("core", t, i)),
                    },
                    {
                        title: translations["top-menu--tools--essentials--title"],
                        slug: "general-stuff|unlock-essentials",
                        showUnlock: accessRules.has("general-stuff|unlock-essentials"),
                        userQuotas: userQuotas['essentials-plan'],
                        data: [
                            {
                                slug: "email-marketing-5",
                            },
                            {
                                slug: "booking",
                            },
                            {
                                slug: "social-proof-pop-up",
                            },
                            {
                                slug: "website-chatbot",
                            },
                            {
                                slug: "wordpress",
                            }
                        ].map((t, i) => mapTool("essentials", t, i)),
                    },
                    {
                        title: translations["top-menu--tools--advanced--title"],
                        slug: "general-stuff|unlock-advanced",
                        showUnlock: accessRules.has("general-stuff|unlock-advanced"),
                        userQuotas: userQuotas['advanced-plan'],
                        data: [
                            {
                                slug: "funnel-builder",
                            },
                            {
                                slug: "whatsapp-central",
                            },
                            {
                                slug: "sms",
                            },
                            {
                                slug: "messenger-chatbot",
                            },
                            {
                                slug: "crm", // top-menu--tools--crm
                            },
                            {
                                slug: "elearning",
                            },
                            {
                                slug: "team-access", // top-menu--tools--team-access
                            },
                        ].map((t, i) => mapTool("advanced", t, i)),
                    },
                    {
                        title: translations["top-menu--tools--premium--title"],
                        slug: "general-stuff|unlock-premium",
                        showUnlock: accessRules.has("general-stuff|unlock-premium"),
                        userQuotas: userQuotas['premium-plus-plan'],
                        data: [
                            {
                                slug: "video-hosting",
                            },
                            {
                                slug: "webinar",
                            },
                            {
                                slug: "quiz",
                            },
                            {
                                slug: "magazine-builder",
                            },
                            {
                                slug: "telegram",
                            },
                        ].map((t, i) => mapTool("premium", t, i)),
                    },
                ],
            },
            {
                id: 2,
                element: translations["top-menu--university--name"],
                slug: "university",
                type: "normal",
                left_title: translations["top-menu--university--title"],
                description: translations["top-menu--university--description"],
                data: [
                    {
                        title: translations["top-menu--university--training--title"],
                        slug: "university_training",
                        icon: require("bundle-text:./assets/university-training.svg"),
                        data: [
                            "quick-start-training",
                            "email-marketing-mailingboss",
                            "local-business-website-agency-course",
                            "facebook-ads-course",
                            "mailingboss-minicourse-practice",
                            "builderall-university-live",
                            "tybl-bonuses",
                            "bootcamp",
                            "es-niche-training",
                            "mailingboss-extreme",
                            "es-builderall-university",
                            "secretos-de-afiliados-top",
                            "cheetah-quick-website-creation",
                            "viral-team-italia",
                            "curso-afiliados-hotmart",
                            "email-marketing-mastery-course"
                        ]
                            .filter(slug => accessRules.has(`training|${slug}`))
                            .map((slug, i) => ({
                                title: translations[`top-menu--university--training--${slug}--title`],
                                description: translations[`top-menu--university--training--${slug}--description`],
                                href: this.proxyURL(`training|${slug}`),
                                target: "_self",
                                show: i < 5,
                            })),
                    },
                    {
                        title: translations["top-menu--university--add-ons--title"],
                        slug: "university_add_ons",
                        icon: require("bundle-text:./assets/university-add-ons.svg"),
                        data: [
                            "inbox-path",
                            "solo-ads",
                            "tybl-solo-ads",
                        ]
                            .filter(slug => accessRules.has(`add-ons|${slug}`))
                            .map((slug, i) => ({
                                title: translations[`top-menu--university--add-ons--${slug}--title`],
                                description: translations[`top-menu--university--add-ons--${slug}--description`],
                                href: this.proxyURL(`add-ons|${slug}`),
                                target: "_blank",
                                show: i < 5,
                            })),
                    },
                ],
            },
            {
                id: 3,
                element: translations["top-menu--connect--name"],
                slug: "connect",
                type: "sections",
                data: [
                    {
                        title: translations["top-menu--connect--community--title"],
                        slug: "connect_community",
                        description: translations["top-menu--connect--community--description"],
                        data: [
                            {
                                icon: require("bundle-text:./assets/connect-linkedin.svg"),
                                slug: "linkedin",
                            },
                            {
                                icon: require("bundle-text:./assets/connect-facebook.svg"),
                                modal: "facebook-community-modal",
                                slug: "facebook",
                            },
                            {
                                icon: require("bundle-text:./assets/connect-instagram.svg"),
                                slug: "instagram",
                            },
                            {
                                icon: require("bundle-text:./assets/connect-tiktok.svg"),
                                slug: "tiktok",
                            },
                            {
                                icon: require("bundle-text:./assets/connect-youtube.svg"),
                                slug: "youtube",
                            },
                        ]
                            .map(t => ({...t, slug: `community|${t.slug}`}))
                            .map((t) => ({
                                ...t,
                                href: this.proxyURL(t.slug),
                                target: "_blank",
                            })),
                    },
                    {
                        title: translations["top-menu--connect--real-time-support--title"],
                        slug: "connect_real_time_support",
                        description: translations["top-menu--connect--real-time-support--description"],
                        data: [
                            {
                                icon: require("bundle-text:./assets/connect-ticket.svg"),
                                slug: "ticket",
                            },
                            {
                                icon: require("bundle-text:./assets/connect-chat.svg"),
                                slug: "chat",
                            },
                            {
                                icon: require("bundle-text:./assets/success-coaches.svg"),
                                slug: "success-coaches",
                            },
                        ]
                            .filter(t => accessRules.has(`real-time-support|${t.slug}`))
                            .map((t) => ({
                                ...t,
                                title: translations[`top-menu--connect--real-time-support--${t.slug}--title`],
                                href: this.proxyURL(`real-time-support|${t.slug}`),
                                target: "_self",
                            })),
                    },
                    {
                        title: translations["top-menu--connect--help--title"],
                        slug: "connect_help",
                        description: translations["top-menu--connect--help--description"],
                        data: [
                            "support",
                            "certified-partners",
                            "knowledgebase",
                            "blog",
                            "events-calendar",
                            "builderall-learn",
                        ]
                            .filter(slug => accessRules.has(`help|${slug}`))
                            .map(slug => ({
                                title: translations[`top-menu--connect--help--${slug}--title`],
                                href: this.proxyURL(`help|${slug}`),
                                target: slug === "blog" ? "_blank" : "_self",
                            })),
                    },
                ],
            },
            ...[
                {
                    id: 4,
                    element: translations["top-menu--franchise-system--name"],
                    slug: "top-menu|franchise-system",
                },
                {
                    id: 5,
                    element: translations["top-menu--become-affiliate--name"],
                    slug: "top-menu|become-affiliate",
                },
                {
                    id: 6,
                    element: translations["top-menu--concession--name"],
                    slug: "top-menu|concession",
                },
            ]
                .filter(t => accessRules.has(t.slug))
                .map(t => ({
                    ...t,
                    type: "link",
                    link: this.proxyURL(t.slug),
                    target: "_self",
                })) as any[],
        ];
    }

    mountHeaderTooltip(resources: Resources) {
        const elRightMenu = this.shadowRoot.getElementById("header-right-ul");

        elRightMenu.innerHTML = this.getHeaderTooltipItems(resources)
            .map(({slug, href, target, icon, tooltip}) => {
                let tag = slug == 'notifications' ? 'button' : 'a';
                return `
                    <li>
                        <${tag} class="header-right-btn ${tag == 'button' ? 'notifications-icon' : ''}" href="${href}" target="${tag == 'a' ? target : ''}">${icon}</${tag}>
                        <div class="tooltip">
                            <p class="tooltip-message">${tooltip}</p>
                        </div>
                    </li>`;
            }).join("\n");

        const elDropdownAvatarItem = this.shadowRoot.getElementById("dropdown-avatar");

        elRightMenu.querySelectorAll("li").forEach(elItem => {
            elItem.querySelector(".header-right-btn").addEventListener("mouseenter", () => {
                elItem.querySelector<HTMLElement>(".tooltip")!.style.display = "flex";
                elDropdownAvatarItem.style.display = "none";
                this.showOrHideOverlay("hide");
            });

            elItem.querySelector(".header-right-btn").addEventListener("mouseleave", () => {
                elItem.querySelector<HTMLElement>(".tooltip").style.display = "none";
            });
        });
    }

    getHeaderTooltipItems({translations, hasNotifications}: Resources) {
        let notificationPath = require("bundle-text:./assets/normal_notification.svg");
        if(hasNotifications){
            notificationPath = require("bundle-text:./assets/notification.svg");
        }

        return [
            {
                icon: require("bundle-text:./assets/knowledgebase.svg"),
                slug: "knowledgebase",
            },
            {
                icon: require("bundle-text:./assets/quick-meeting.svg"),
                slug: "quick-meeting",
            },
            {
                icon: require("bundle-text:./assets/support.svg"),
                slug: "support",
            },
            {
                icon: notificationPath,
                slug: "notifications",
            },
        ].map(t => ({
            ...t,
            tooltip: translations[`general-stuff--${t.slug}-title`],
            href: this.proxyURL(`general-stuff|${t.slug}`),
            target: t.slug === "quick-meeting" ? "_blank" : "_self",
        }));
    }

    defineAddEventListenerToHeaderRight(){
        const notificationsIconElement = this.shadowRoot?.querySelector<HTMLLinkElement>(".notifications-icon");

        notificationsIconElement.addEventListener("click", async () => {
            const href = notificationsIconElement.getAttribute('href');
            await this.sendRequest(`wrapper/notifications/read-all`, {
                method: "PUT",
            });
            window.open(href, "_self");
        })
    }

    mountSidebarElements(resources: Resources) {
        const sidebarTemplate = require("bundle-text:./templates/elements-main-sidebar.html");
        const sidebarItems = this.getSidebarItems(resources);

        sidebarItems.forEach(it => {
            if(it.show == true || it == "line"){
                const li = document.createElement("li");
                li.innerHTML = sidebarTemplate;
                const sidebarElementLinkElement: HTMLAnchorElement = li.querySelector(".sidebar-element-link");
    
                const setTitleAndIcon = (): void => {
                    li.querySelector(".sidebar-element-icon")!.innerHTML = it.icon;
                    li.querySelector(".sidebar-element-title")!.textContent = it.title;
                }

                if (it == "line" || it.data == "line") {
                    let newHr = document.createElement("hr");
                    li.appendChild(newHr);
                    sidebarElementLinkElement!.style.display = "none";
                } else if (it.href && it.type == "link") {
                    sidebarElementLinkElement!.href = it.href;
                    sidebarElementLinkElement!.target = it.target || "_self";
                    setTitleAndIcon();
                    if (this.getAttribute("tool") === it.slug) li.classList.add("active");
                } else if (it.type == "dropdown") {
                    setTitleAndIcon();
    
                    // Data to right dropdown
                    if (it.data) {
                        li.querySelector(".sidebar-element-title")!.innerHTML += require("bundle-text:./assets/arrow-right.svg");
                        li.querySelector(".dropdown-sidebar")?.classList.add("show");
                        li.querySelector(".dropdown-title")!.textContent = it.data.title;
                        li.querySelector(".dropdown-description")!.textContent = it.data.description;
    
                        let content = li.querySelector(".dropdown-content");
    
                        it.data.data.forEach(e => {
                            let newA = document.createElement("a");
                            newA.className = "sidebar-dropdown-element";
                            let newDiv = document.createElement("div");
                            let newP = document.createElement("p");
                            let newSpan = document.createElement("span");
    
                            newP.textContent = e.title;
                            content?.classList.add("lines");
                            newA.href = e.href;
                            newA.target = e.target;
                            newSpan.innerHTML = require("bundle-text:./assets/line-arrow-right.svg");
    
                            content?.appendChild(newA);
                            newA.appendChild(newDiv);
                            newDiv.appendChild(newP);
                            if (e.subtitle) {
                                let newSubtitle = document.createElement("p");
                                newSubtitle.textContent = e.subtitle;
                                newDiv.className = "has-subtitle";
                                newDiv.appendChild(newSubtitle);
                            }
                            newA.appendChild(newSpan);
    
                            if (e.locked) {
                                const lock = document.createElement("i");
                                lock.className = "access-rules-lock";
                                lock.innerHTML = require("bundle-text:./assets/lock.svg");
                                lock.title = "Unavailable, requires plan upgrade";
                                newP.append(lock);
                            }
                        });
                    }
                }
                if (it.type === "modal") {
                    setTitleAndIcon();
                    li.addEventListener("click", () => this.openGetStartedModal());
                } else {
                    // under construction
                    setTitleAndIcon();
                    // Show tooltip
                    // sidebarElementLinkElement?.setAttribute("ba-tooltip-position", "right");
                    // sidebarElementLinkElement?.setAttribute("ba-tooltip-title", it.tooltip);
    
                    // li.setAttribute("title", it.tooltip);
                }
    
                // Actions
                this.defineAddEventListenerToSidebarElements(li);
                this.shadowRoot?.getElementById("main-sidebar-ul")!.appendChild(li);
            }
        });
    }

    defineAddEventListenerToSidebarElements(element: HTMLElement) {
        const mainSidebarElement = this.shadowRoot?.querySelector(".main-sidebar");
        const dropdownSidebarElement: HTMLDivElement = element.querySelector(".dropdown-sidebar");
        const sidebarElementLinkElement = element.querySelector(".sidebar-element-link");
        const headerCenterElement = this.shadowRoot!.querySelector(".header-center");
        const rm = this;

        // Open Sidebar
        mainSidebarElement?.addEventListener("mouseenter", () => {
            mainSidebarElement?.classList.add("opened");
            this.shadowRoot?.querySelector(".header-left")?.classList.add("show");
            rm.showOrHideOverlay("show");
        });

        // Close Sidebar and close dropdwons
        mainSidebarElement?.addEventListener("mouseleave", () => {
            let timer = setTimeout(() => {
                this.shadowRoot!.querySelector<HTMLElement>(".wrapper-main")!.style.height = "auto";
                this.shadowRoot?.querySelector(".header-left")?.classList.remove("show");
                mainSidebarElement?.classList.remove("opened");
                this.shadowRoot?.querySelectorAll<HTMLElement>(".sidebar-element-link").forEach(el => el.classList.remove("active"));
                element.querySelectorAll<HTMLElement>(".dropdown-sidebar").forEach(el => el.style.display = "none");

                // Doesn't remove overlay if mouse is inside header
                if (!headerCenterElement!.matches(":hover")) {
                    rm.showOrHideOverlay("hide");
                }
            }, 200);
            dropdownSidebarElement?.addEventListener("mouseenter", () => {
                clearTimeout(timer);
            });
            mainSidebarElement?.addEventListener("mouseenter", () => {
                clearTimeout(timer);
            });
            // Set z-index
            this.shadowRoot?.querySelectorAll<HTMLElement>(".dropdown-sidebar").forEach(el => el.style.zIndex = "1");
        });

        // Hover in sidebar elements
        if (dropdownSidebarElement?.classList.contains("show")) {
            // Show Right Dropdown
            sidebarElementLinkElement?.addEventListener("click", e => {
                this.shadowRoot?.querySelectorAll<HTMLElement>(".dropdown-sidebar").forEach(el => {
                    el.style.display = "none";
                    el.style.zIndex = "-1";
                    el.style.maxHeight = "auto";
                    el.parentElement.querySelector(".sidebar-element-link").classList.remove("active");
                });

                element.querySelector<HTMLElement>(".dropdown-sidebar")!.style.display = "flex";
                sidebarElementLinkElement.classList.add("active");

                const linkTop = sidebarElementLinkElement.getBoundingClientRect().top;
                const dropdownHeight = dropdownSidebarElement.getBoundingClientRect().height;
                const dropdownMiddle = dropdownHeight / 2;

                const marginTop = 80 + 32;
                const offsetTop = linkTop - dropdownMiddle > marginTop ? dropdownMiddle : linkTop - marginTop;

                dropdownSidebarElement.style.top = `-${offsetTop}px`;

                const marginBottom = 32;
                const dropdownMaxHeight = window.innerHeight - (linkTop - offsetTop) - marginBottom;

                dropdownSidebarElement.style.maxHeight = `${dropdownMaxHeight}px`;
            });
        } else {
            // Remove active class and close all dropdowns
            sidebarElementLinkElement?.addEventListener("mouseenter", () => {
                this.shadowRoot?.querySelectorAll<HTMLElement>(".sidebar-element-link").forEach(el => el.classList.remove("active"));
                this.shadowRoot?.querySelectorAll<HTMLElement>(".dropdown-sidebar").forEach(el => el.style.display = "none");
            });
        }
    }

    getSidebarItems({accessRules, translations}: Resources) {
        const mapTool = (prefix: string, slug: string) => {
            const hasAccess = accessRules.has(`${prefix}|${slug}`);
            return {
                title: translations[`left-menu--${prefix}--${slug}--title`],
                subtitle: translations[`left-menu--${prefix}--${slug}--subtitle`],
                locked: !hasAccess,
                href: hasAccess
                    ? this.proxyURL(`${prefix}|${slug}`)
                    : this.proxyURL("general-stuff|checkout-plans"),
                target: hasAccess ? "_self" : "_blank",
            };
        };

        return [
            {
                id: 1,
                icon: require("bundle-text:./assets/dashboard.svg"),
                title: translations["left-menu--home--name"],
                type: "link",
                show: true,
                href: this.proxyURL("left-menu|home"),
                slug: "office",
            },
            {
                data: "line",
                show: accessRules.has(`left-menu|get-started`)
            },
            {
                id: 2,
                icon: require("bundle-text:./assets/get-started.svg"),
                title: translations["left-menu--get-started--name"],
                type: "modal",
                show: accessRules.has(`left-menu|get-started`)
            },
            "line",
            {
                id: 3,
                icon: require("bundle-text:./assets/business-center.svg"),
                title: translations["left-menu--business-center--name"],
                type: "dropdown",
                show: true,
                data: {
                    title: translations["left-menu--business-center--title"],
                    description: translations["left-menu--business-center--description"],
                    data: [
                        "dns-manager",
                        "url-shortener",
                        "team-access",
                        "crm",
                    ].map(slug => mapTool("business-center", slug)),
                },
            },
            "line",
            {
                id: 4,
                icon: require("bundle-text:./assets/build.svg"),
                title: translations["left-menu--build--name"],
                type: "dropdown",
                show: true,
                data: {
                    title: translations["left-menu--build--title"],
                    description: translations["left-menu--build--description"],
                    data: [
                        "website-builder",
                        "email-marketing-5",
                        "booking",
                        "elearning",
                        "webinar",
                        "magazine-builder",
                    ].map(slug => mapTool("build", slug)),
                },
            },
            {
                id: 5,
                icon: require("bundle-text:./assets/engage.svg"),
                title: translations["left-menu--engage--name"],
                type: "dropdown",
                show: true,
                data: {
                    title: translations["left-menu--engage--title"],
                    description: translations["left-menu--engage--description"],
                    data: [
                        "email-marketing-5",
                        "messenger-chatbot",
                        "website-chatbot",
                        "sms",
                        "browser-notification",
                        "social-proof-pop-up",
                    ].map(slug => mapTool("engage", slug)),
                },
            },
            "line",
        ] as any;
    }

    async mountAvatarElements(resources: Resources) {
        const avatarElement = this.shadowRoot.getElementById('dropdown-avatar');
        const emailIcon1Element = this.shadowRoot!.querySelector<HTMLSpanElement>('.ai-email-icon-1');
        const emailIcon2Element = this.shadowRoot!.querySelector<HTMLSpanElement>('.ai-email-icon-2');
        const planNameIcon1Element = this.shadowRoot!.querySelector<HTMLSpanElement>('.da-pi-tl-plan-name-icon-1');
        const planNameIcon2Element = this.shadowRoot!.querySelector<HTMLSpanElement>('.da-pi-tl-plan-name-icon-2');


        var [profileUserData, profileQuotas, profileDomains, profileDomainsEmails] = await Promise.all([
            this.getProfileUserData(),
            this.getProfileQuotas(),
            this.getProfileDomains(),
            this.getProfileDomainsEmails()
        ]);

        if(profileUserData.user.privacy.hide_email){
            emailIcon2Element.style.display = 'flex';
            emailIcon1Element.style.display = 'none';
        } else {
            emailIcon1Element.style.display = 'flex';
            emailIcon2Element.style.display = 'none';
        }

        avatarElement.querySelector('.da-ti-name').textContent = resources.translations['avatar-hi'] + ', ' + profileUserData.user.name;
        avatarElement.querySelector<HTMLLinkElement>('.da-ti-manage-account').href = this.proxyURL(`right-menu|settings`);

        // Define plan data
        var periodToPay = ''
        switch (profileUserData.plan?.period) {
            case 'ANUAL':
                periodToPay = resources.translations['avatar-yearly'];
                break;

            case 'SEMESTRAL':
                periodToPay = resources.translations['avatar-biannual'];
                break;

            case 'MENSAL':
                periodToPay = resources.translations['avatar-monthly'];
                break;

            case 'TRIMESTRAL':
                periodToPay = resources.translations['avatar-quarterly'];
                break;

            default:
                periodToPay = '';
        }

        if(profileUserData.plan?.title){
            if(profileUserData.user.privacy.hide_plan){
                planNameIcon2Element.style.display = 'flex';
                planNameIcon1Element.style.display = 'none';
            } else {
                planNameIcon1Element.style.display = 'flex';
                planNameIcon2Element.style.display = 'none';
            }
            avatarElement.querySelector('.da-pi-tl-plan-name').textContent = profileUserData.plan.title;
        }

        if(profileUserData.plan?.invoices_route){
            avatarElement.querySelector<HTMLLinkElement>('.da-pi-top-plan-access').href = profileUserData.plan.invoices_route;
        } else {
            avatarElement.querySelector<HTMLLinkElement>('.da-pi-top-plan-access').style.display = 'none';
        }

        if(profileUserData.plan?.symbol){
            avatarElement.querySelector('.da-pi-tl-plan-value').textContent = profileUserData.plan.symbol + profileUserData.plan?.value + '/' + periodToPay;
        }

        if(profileUserData.plan?.next_payment_date){
            avatarElement.querySelector('.da-pi-tl-plan-next-payment').textContent = resources.translations['avatar-next-payment-date'] + ': ' + profileUserData.plan?.next_payment_date;
        }

        if(!resources.whiteLabelData?.is_builderall){
            const planData = avatarElement.querySelectorAll('.da-pi-plan-data')

            planData.forEach(element => {
                (element as HTMLElement).style.display = 'none';
            })
        }

        if(!profileUserData.plan?.title && !profileUserData.plan?.invoices_route && !profileUserData.plan?.symbol && !profileUserData.plan?.next_payment_date){
            avatarElement.querySelector<HTMLHRElement>('.da-plan-info .profile-line').style.display = 'none';
            avatarElement.querySelector<HTMLHRElement>('.da-plan-info .da-pi-top').style.display = 'none';
            avatarElement.querySelector<HTMLSpanElement>('.da-subtitle-plan').textContent = '';
        }

        const whiteLabelElement = avatarElement.querySelector<HTMLLinkElement>('.da-pi-tl-wl-access');
        if(profileUserData.plan?.is_white_label){
            whiteLabelElement.href = this.proxyURL('right-menu|white-label');
        } else {
            whiteLabelElement.style.display = 'none';
        }
        
        // Define sponsor data
        if(profileUserData.user.parent){
            avatarElement.querySelector<HTMLSpanElement>('.da-pi-bs-name')
                .textContent = profileUserData.user.parent.name;
        } else {
            avatarElement.querySelector<HTMLSpanElement>('.da-pi-bottom').style.display = 'none'
            avatarElement.querySelector<HTMLDivElement>('.da-pi-bottom-profile-line').style.display = 'none'
        }

        avatarElement.querySelector('.da-pi-bs-id').textContent = profileUserData.user.id;

        // Progress domains data
        const domainsProgressElement = avatarElement.querySelector<HTMLLinkElement>('.da-pi-me-domains');
        if (profileQuotas?.dns) {
            domainsProgressElement.href = this.proxyURL('dns-manager', {
                "w_redir": "1",
                "w_history_url": profileQuotas?.dns?.route,
                "w_type": "tool",
            });
        }

        const connectedDomains = profileQuotas?.dns?.usage?.connected || 0;
        const limitDomains = profileQuotas?.dns?.usage?.limit || 0;
        domainsProgressElement.querySelector<HTMLProgressElement>('#da-progress-domains').value = connectedDomains;
        domainsProgressElement.querySelector<HTMLProgressElement>('#da-progress-domains').setAttribute("max", Number(limitDomains).toString());
        domainsProgressElement.querySelector<HTMLProgressElement>('.da-pi-main-qty').textContent = connectedDomains + ' ' + resources.translations['avatar-of'] + ' ' + limitDomains;

        // Progress hard drive disk data
        const hardDriveSpaceProgressElement = avatarElement.querySelector<HTMLLinkElement>('.da-pi-me-hard-drive-space');
        if (profileQuotas?.storage) {
            hardDriveSpaceProgressElement.href = this.proxyURL('office', {
                "w_redir": "1",
                "w_history_url": profileQuotas?.storage?.route,
                "w_type": "tool",
            });
        }

        const storageInUse = profileQuotas.storage.in_use || 0;
        const storageReleased = profileQuotas.storage.released || 0;
        hardDriveSpaceProgressElement.querySelector<HTMLProgressElement>('#da-progress-hard-drive-space').value = storageInUse;
        hardDriveSpaceProgressElement.querySelector<HTMLProgressElement>('#da-progress-hard-drive-space').setAttribute("max", Number(storageReleased).toString());
        hardDriveSpaceProgressElement.querySelector<HTMLProgressElement>('.da-pi-main-qty-left').textContent = storageInUse + ' MB';
        hardDriveSpaceProgressElement.querySelector<HTMLProgressElement>('.da-pi-main-qty-right').textContent = this.convertMBtoGB(storageReleased) + ' GB';

        // Progress subscribers data
        const subscribersProgressElement = avatarElement.querySelector<HTMLLinkElement>('.da-pi-me-subscribers');
        if (profileQuotas?.mailingboss) {
            subscribersProgressElement.href = this.proxyURL('email-marketing-5', {
                "w_redir": "1",
                "w_history_url": profileQuotas?.mailingboss?.route,
                "w_type": "tool",
            });
        }

        const limitSubscribers = profileQuotas?.mailingboss?.limit_subscribers || 0;
        const mbSubscribers = profileQuotas?.mailingboss?.subscribers || 0;
        const mbPercentage = profileQuotas?.mailingboss?.percentage_usage || 0;

        subscribersProgressElement.querySelector<HTMLProgressElement>('#da-progress-subscribers').value = mbSubscribers;
        subscribersProgressElement.querySelector<HTMLProgressElement>('#da-progress-subscribers').setAttribute("max", Number(limitSubscribers).toString());

        subscribersProgressElement.querySelector<HTMLProgressElement>('.da-pi-main-qty-left').textContent = mbSubscribers + ' ' + resources.translations['avatar-of'] + ' ' + limitSubscribers;
        subscribersProgressElement.querySelector<HTMLProgressElement>('.da-pi-main-qty-right').textContent = mbPercentage;

        // Define domains list
        const listDomainsElement = avatarElement.querySelector<HTMLLinkElement>('.da-at-ts-list-domains');
        if(profileDomains.domains.length > 0){
            profileDomains.domains.forEach(e => {
                let newLink = document.createElement("a");

                newLink.href = this.proxyURL("dns-manager", {
                    "w_redir": "1",
                    "w_history_url": (profileDomains?.route || "").replace(":domain-id", e.id),
                    "w_type": "tool",
                });

                newLink.textContent = e.label;
                
                listDomainsElement.appendChild(newLink);
            });
        } else {
            let newLink = document.createElement("a");
    
            newLink.href = !profileDomains.route ? '' : profileDomains.route;
            newLink.textContent = resources.translations['avatar-no-domains'];
            
            listDomainsElement.appendChild(newLink);
        }

        // Define domain url
        avatarElement.querySelector<HTMLLinkElement>(".da-at-tool-domain")
            .href = this.proxyURL("dns-manager", {
            "w_redir": "0",
            "w_type": "tool",
        });

        const listDomainsEmailsElement = avatarElement.querySelector<HTMLLinkElement>('.da-at-ts-list-emails');
        if(profileDomainsEmails.domains_with_emails.length > 0){
            profileDomainsEmails.domains_with_emails.forEach(e => {
                let newLink = document.createElement("a");

                newLink.href = profileDomainsEmails.route.replace(':domain-id', e.id);
                newLink.textContent = '@'+e.label;
                
                listDomainsEmailsElement.appendChild(newLink);
            });
        } else {
            let newLink = document.createElement("a");

            newLink.href = !profileDomains.route ? '' : profileDomains.route;
            newLink.textContent = resources.translations['avatar-no-emails'];
            
            listDomainsEmailsElement.appendChild(newLink);
        }

        // Define Language
        const languageSelectElement = avatarElement.querySelector('.da-language-select');
        const languages = [
            {value: 'us', slug: 'en_US', title: resources.translations['avatar-english']},
            {value: 'br', slug: 'pt_BR', title: resources.translations['avatar-portuguese']},
            {value: 'it', slug: 'it_IT', title: resources.translations['avatar-italian']},
            {value: 'fr', slug: 'fr_FR', title: resources.translations['avatar-french']},
            {value: 'es', slug: 'es_ES', title: resources.translations['avatar-spanish']},
            {value: 'de', slug: 'de_DE', title: resources.translations['avatar-german']},
        ];

        languages.forEach(e => {
            let newButton = document.createElement("button");
            newButton.textContent = e.title;
            newButton.value = e.value;

            languageSelectElement.appendChild(newButton);
        })

        avatarElement.querySelector('.da-lb-language-title').textContent = languages.find(x => x.slug == profileUserData.user.lang).title;

        // Logout button
        avatarElement.querySelector<HTMLLinkElement>('.da-logout-button').href = this.proxyURL(`right-menu|logout`);

        this.defineAddEventListenerToAvatarElements(avatarElement);
    }

    defineAddEventListenerToAvatarElements(element: HTMLElement) {
        const avatarSection = this.shadowRoot?.querySelector(".avatar-section");
        const dropdownElementLinkElement = element.querySelector(".dropdown-element-link");
        const dropdownAvatarElement = this.shadowRoot!.getElementById("dropdown-avatar");
        const copySponsorIdElement = this.shadowRoot!.getElementById("da-pi-bs-copy-sponsor");
        const languageSelectElement = this.shadowRoot!.querySelector('.da-language-select');
        const emailElement = this.shadowRoot!.querySelector<HTMLSpanElement>('.avatar-info-email');
        const emailIcon1Element = this.shadowRoot!.querySelector<HTMLSpanElement>('.ai-email-icon-1');
        const emailIcon2Element = this.shadowRoot!.querySelector<HTMLSpanElement>('.ai-email-icon-2');
        const planNameElement = this.shadowRoot!.querySelector<HTMLSpanElement>('.da-pi-tl-plan-name');
        const planNameIcon1Element = this.shadowRoot!.querySelector<HTMLSpanElement>('.da-pi-tl-plan-name-icon-1');
        const planNameIcon2Element = this.shadowRoot!.querySelector<HTMLSpanElement>('.da-pi-tl-plan-name-icon-2');
        const planValueElement = this.shadowRoot!.querySelector<HTMLSpanElement>('.da-pi-tl-plan-value');
        const openSendMessageToSponsorModalButton = this.shadowRoot!.querySelector<HTMLButtonElement>('.open-send-message-to-sponsor-modal');
        const sendMessageToSpondorModalElement = this.shadowRoot!.getElementById('send-message-to-spondor-modal');
        const modalSendMessageToSponsor = this.shadowRoot!.getElementById("send-message-to-spondor-modal");
        const closeSendMessageToSponsorModalButton = this.shadowRoot!.querySelector<HTMLButtonElement>('.open-send-message-to-sponsor-modal');
        const sendMessageToSpondorRequest = this.shadowRoot!.querySelector<HTMLButtonElement>('.send-message-to-sponsor-btn');
        const sendMessageToSponsorTextarea = this.shadowRoot!.querySelector<HTMLButtonElement>('.modal-content-textarea');
        const rm = this;
        const modalState: ToggleModal = {
            setToggleModal: true
        };

        // Open Main Dropdown
        avatarSection?.addEventListener("mouseenter", () => {
            dropdownAvatarElement!.style.display = "flex";
            rm.showOrHideOverlay("show");
        });

        // Hide Dropdown
        function closeDropdown() {
            dropdownAvatarElement!.style.display = "none";
            rm.showOrHideOverlay("hide");
        }

        dropdownAvatarElement?.addEventListener("mouseleave", () => {
            let timer = setTimeout(() => {
                closeDropdown();
            }, 200);
            dropdownElementLinkElement?.addEventListener("mouseenter", () => {
                clearTimeout(timer);
            });
            dropdownAvatarElement?.addEventListener("mouseenter", () => {
                clearTimeout(timer);
            });
        });

        // Copy sponsor id
        copySponsorIdElement.addEventListener("click", () => {
            const textToCopy = this.shadowRoot!.querySelector(".da-pi-bs-id").textContent;
    
            navigator.clipboard.writeText(textToCopy)
                .catch(function(err) {
                    console.error('Error to copy sponsor id: ', err);
                });
        })

        // Show send message to sponsor modal
        openSendMessageToSponsorModalButton.addEventListener("click", () => {
            if( modalState.setToggleModal ==  true){
                sendMessageToSpondorModalElement.style.display = "flex";
                sendMessageToSpondorModalElement.style.backdropFilter = "brightness(0.5)";
            }
        })

        closeSendMessageToSponsorModalButton.addEventListener("click", () => {
            if(!modalState.setToggleModal ==  true){
                sendMessageToSpondorModalElement.style.display = "none";
            }
        })
        // Eye icon to show or hide elements
        // email
        const originalEmail = emailElement.textContent;

        if(emailIcon1Element.style.display == 'flex'){
            showEmail()
        } else {
            hideEmail()
        }
        

        emailIcon1Element?.addEventListener("click", async () => {
            hideEmail()
        
            const data = new URLSearchParams();
            data.append('type', 'email');
            data.append('value', 'true');
            
            await this.sendRequest(`profile/privacy-eye`, {
                method: "PUT",
                body: data
            });
        });

        sendMessageToSponsorTextarea.addEventListener("keydown", () => {
            if (sendMessageToSponsorTextarea.value.length > 1) {
                enableButton()
            } else {
                disableButton()
            }
        })

        function disableButton() {
            sendMessageToSpondorRequest.classList.add("ba-disabled")
            sendMessageToSpondorRequest.disabled = true
        }

        function enableButton() {
            sendMessageToSpondorRequest.classList.remove("ba-disabled")
            sendMessageToSpondorRequest.disabled = false
        }

        emailIcon2Element?.addEventListener("click", async () => {
            showEmail()

            const data = new URLSearchParams();
            data.append('type', 'email');
            data.append('value', 'false');
            
            await this.sendRequest(`profile/privacy-eye`, {
                method: "PUT",
                body: data
            });
        });

        sendMessageToSpondorRequest?.addEventListener("click", async () => {
            const data = new URLSearchParams();
            const {user} = await this.getProfileUserData();
            const test = sendMessageToSponsorTextarea.value;

            data.append('category', 'sponsoredMessage');
            data.append('message', user.email);
            data.append('description', test);
            data.append('from', 'office-api');
            data.append('owner', user.parent.email);
            try{ 
                disableButton()
                await this.sendRequest(`wrapper/notifications/create`, {
                    method: "POST",
                    body: data
                });
            } catch (error) {
                console.error(error, 'error');
            } finally {
                sendMessageToSpondorModalElement.style.display = "none";
                sendMessageToSponsorTextarea.value = "";
            }
        });

        if("click" || sendMessageToSpondorRequest){
            closeModalSendBtn();
        }

        function hideEmail(){
            emailIcon1Element.style.display = 'none';
            emailIcon2Element.style.display = 'block';
            emailElement.textContent = `${originalEmail.slice(0, 3)}••••••••••••••••`;
        }

        function showEmail(){
            emailIcon1Element.style.display = 'block';
            emailIcon2Element.style.display = 'none';
            emailElement.textContent = originalEmail;
        }
        function closeModalSendBtn(){
            sendMessageToSpondorModalElement.style.display = 'none';
        }

        // plan
        const planNameOrigial = planNameElement.textContent;
        const planValueOrigial = planValueElement.textContent;

        if(planNameIcon1Element.style.display == 'flex'){
            showPlanInformations()
        } else {
            hidePlanInformations()
        }

        planNameIcon1Element?.addEventListener("click", async () => {
            hidePlanInformations()

            const data = new URLSearchParams();
            data.append('type', 'plan');
            data.append('value', 'true');
            
            await this.sendRequest(`profile/privacy-eye`, {
                method: "PUT",
                body: data
            });
        });

        planNameIcon2Element?.addEventListener("click", async () => {
            showPlanInformations();

            const data = new URLSearchParams();
            data.append('type', 'plan');
            data.append('value', 'false');

            await this.sendRequest(`profile/privacy-eye`, {
                method: "PUT",
                body: data
            });
        });

        function hidePlanInformations(){
            planNameIcon1Element.style.display = 'none';
            planNameIcon2Element.style.display = 'block';
            planNameElement.textContent = '••••••';
            planValueElement.textContent = `${planValueOrigial.slice(0, 1)}••••••`;
        }

        function showPlanInformations(){
            planNameIcon1Element.style.display = 'block';
            planNameIcon2Element.style.display = 'none';
            planNameElement.textContent = planNameOrigial;
            planValueElement.textContent = planValueOrigial;
        }

        // Change language
        languageSelectElement.querySelectorAll("button").forEach(button => {
            button.addEventListener("click", async () => {
                this.shadowRoot!.querySelector<HTMLSpanElement>('.da-lb-language-title').textContent = button.textContent;

                await this.sendRequest("profile/update-lang", {
                    method: "PUT",
                    body: new URLSearchParams({language: button.value}),
                });

                if(this.getAttribute("tool") == 'office'){
                    location.reload();
                } else {
                    window.location.replace(this.proxyURL(this.getAttribute("tool"), {
                        "w_redir": "1",
                        "w_type": "tool",
                    }));
                }
            });
        });
    }

    isEmpty(val) {
        return val === undefined || val == null || val.length <= 0 || (typeof val === 'object' && Object.keys(val).length === 0) ? true : false;
    }

    convertMBtoGB(mb: number): number {
        return mb / 1024;
    }

    async getUserQuotas() {
        return await this.sendRequest("wrapper/plans-quotas")
    }

    async getProfileUserData(){
        return await this.sendRequest("profile");
    }

    async getProfileQuotas(){
        return await this.sendRequest("profile/quotas");
    }

    async getProfileDomains(){
        return await this.sendRequest("dns/domains");
    }

    async getProfileDomainsEmails(){
        return await this.sendRequest("dns/domains-with-emails");
    }

    isDivBelow(div1, div2) {
        const rect1 = div1.getBoundingClientRect();
        const rect2 = div2.getBoundingClientRect();

        return rect1.top >= rect2.bottom ||
            rect1.right <= rect2.left ||
            rect1.bottom <= rect2.top ||
            rect1.left >= rect2.right;
    }

    mountHistory(resources: Resources) {
        const {history, translations} = resources;

        const sidebar = this.shadowRoot.getElementById("main-sidebar-ul");

        sidebar.querySelectorAll(".sidebar-history-item")
            .forEach(el => el.remove());

        for (const slug of history) {
            const tool = tools[slug];
            if (!tool) {
                console.warn(`[appshell] tool with slug ${slug} not found`);
                continue;
            }

            const item = document.createElement("li");
            item.classList.add("sidebar-history-item");

            const anchor = document.createElement("a");
            anchor.classList.add("sidebar-element-link");
            anchor.href = this.proxyURL(slug, {
                "w_redir": "1",
                "w_type": "tool",
            });
            anchor.target = "_self";

            if (slug === this.getAttribute("tool")) {
                item.classList.add("is-active");
                anchor.href = "";
            }

            const icon = document.createElement("span");
            icon.classList.add("sidebar-element-icon");
            icon.innerHTML = tool.icon;

            const title = document.createElement("span");
            title.classList.add("sidebar-element-title");
            title.innerText = translations[`left-menu--history--${slug}--title`];

            const remove = document.createElement("div");
            remove.classList.add("sidebar-element-remove");
            remove.innerHTML = require("bundle-text:./assets/history-delete.svg");

            if (slug === this.getAttribute("tool")) {
                item.classList.add("is-active");
                anchor.href = "";
            }

            remove.addEventListener("click", async e => {
                e.preventDefault();
                e.stopPropagation();

                const {history} = await this.removeHistory(slug);
                resources.history = history;

                if (slug !== this.getAttribute("tool")) {
                    this.mountHistory(resources);
                    return;
                }

                const previousToolSlug = history.shift();
                if (!previousToolSlug) {
                    window.open(this.proxyURL("left-menu|home"), "_self");
                    return;
                }

                const previousTool = tools[previousToolSlug];
                if (!previousTool) {
                    console.error(`tool with slug ${previousToolSlug} not found`);
                    return;
                }

                window.open(this.proxyURL(previousTool.slug, {
                    "w_redir": "1",
                    "w_type": "tool",
                }), "_self");
            });

            anchor.append(icon, title, remove);
            item.append(anchor);
            sidebar.append(item);
        }
    }

    async syncHistory() {
        let defaultSlugs = ["ba-support"];

        if (defaultSlugs.includes(this.getAttribute("tool"))) {
            const {history} = await this.getHistory();
            return history;
        }

        const {redir, history} = await this.accessHistoryTick(window.location.href);
        if (redir) setTimeout(() => window.open(redir, "_self"));

        return history;
    }

    async getHistory(): Promise<{ redir?: string, history: string[] }> {
        if (!this.getAttribute("token") || !this.getAttribute("tool")) {
            throw new Error("'tool' attribute must be set before mounting history");
        }

        return await this.sendRequest(`wrapper/history?tool_slug=${this.getAttribute("tool")}`);
    }

    async createHistory(url: string): Promise<{ redir?: string, history: string[] }> {
        const form = new FormData();
        form.set("tool_slug", this.getAttribute("tool"));
        form.set("url", url);

        return await this.sendRequest("wrapper/history/create", {
            method: "POST",
            body: form,
        });
    }

    async removeHistory(slug: string): Promise<{ redir?: string, history: string[] }> {
        return await this.sendRequest(`wrapper/history/delete/${slug}`, {
            method: "DELETE",
        });
    }

    async accessHistoryTick(currentURL: string, previousURL: string = "") {
        try {
            if (currentURL !== previousURL) {
                return await this.createHistory(currentURL);
            }
        } catch (err) {
            console.error("register access history tick to api error", err);
        } finally {
            setTimeout(() => this.accessHistoryTick(window.location.href, currentURL), 500);
        }
    }

    async getAccessRules(): Promise<Set<string>> {
        const reduce = (arm: any) => typeof arm === "string"
            ? [arm] : Object.keys(arm).map(k => reduce(arm[k])).flat();

        return new Set<string>(reduce(await this.sendRequest("wrapper/access-rules")));
    }

    async getWhiteLabel(): Promise<{ logo: string, title: string } | false> {
        const {is_builderall, logo, title} = await this.sendRequest("wl-data");
        return is_builderall ? false : {logo, title};
    }

    async getWhiteLabelData(): Promise<any> {
        const response = await this.sendRequest("wl-data");

        console.log(response)
        return response;
    }

    async getUser(): Promise<{ id: number, name: string, email: string, lang: string }> {
        // return {
            // id: 103027,
            // name: "Anderson",
            // email: "anderson.marin@outlook.com",
            // lang: "pt_BR",
        // };
        return this.sendRequest("user");
    }

    async hasNotifications(){
        let response = await this.sendRequest("wrapper/notifications/all");
        return response.collection.some(x => x.read == false);
    }

    async sendRequest(path: string, options: RequestInit = {}) {
        return sendRequest(this.getAttribute("token"), path, options);
    }

    proxyURL(slug: string, queryParams: { [_: string]: string } = {}): string {
        return proxyURL(this.getAttribute("token"), slug, queryParams);
    }

    public openGetStartedModal({onClose, onChange}: { onChange?, onClose? } = {}) {
        const modal = document.createElement("div");

        this.shadowRoot.append(modal);
            render(() => GetStartedModal({
            checkbox: !!(onClose || onChange),
            token: this.getAttribute("token"),
            onChange: (state) => {
                if (onChange) onChange(state);
            },
            onClose: (state) => {
                modal.remove();
                if (onClose) onClose(state);
            },
        }), modal);
    }
});
