import {createEffect, createSignal, Show} from "solid-js";
import {translations} from "../../../services/context";
import {proxyURL, sendRequest} from "../../../services/office-api";

interface GetStartedModalProps {
    checkbox: boolean;
    token: String;
    onClose: (state: { checked: boolean }) => void;
    onChange: (state: { checked: boolean }) => void;
}

export default function GetStartedModal(props: GetStartedModalProps) {
    const [checked, setChecked] = createSignal(false);

    createEffect(() => {
        props.onChange({checked: checked()});
    });

    return (
        <div class="get-started-modal">
            <div class="get-started-modal__card">
                <div class="get-started-modal__card__title">{translations["left-menu--get-started--name"]}</div>
                <i class="get-started-modal__card__close"
                   innerHTML={require("bundle-text:../assets/close-modal.svg")}
                   onClick={() => props.onClose({checked: checked()})}/>
                <VideoIframe token={props.token}/>
                <Show when={props.checkbox}>
                    <div class="get-started-modal__card__checkbox"
                         classList={{"get-started-modal__card__checkbox--checked": checked()}}
                         onClick={() => setChecked(!checked())}>
                        <div class="get-started-modal__card__checkbox__indicator"/>
                        <div class="get-started-modal__card__checkbox__label">{translations["appshell-dont-show-again"]}</div>
                    </div>
                </Show>
            </div>
        </div>
    );
}

function VideoIframe(props) {
    const [url, setUrl] = createSignal('');

    createEffect(async () => {
        const fetchedUrl = await getVideoUrl(props);
        setUrl(fetchedUrl.url);
    });

    return (
        <iframe class="get-started-modal__card__iframe"
            width="560" height="315" src={`${url()}`} title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen/>
    );
}

async function getVideoUrl(props) {
    return await sendRequest(props.token, `wrapper/url/left-menu|get-started?w_type=wrapper&w_authentication=${props.token}`);
}
