const isEmpty = (val) => {
    return val === 0 || val === undefined || val == null || val.length <= 0 || (typeof val === 'object' && Object.keys(val).length === 0) ? true : false
}

export async function sendGFRequest(token: string, path: string, method: string, body: any): Promise<any> {
    const baseURL = process.env.GLOBAL_FILE_API;

    const url = `${baseURL}/v1/${path}`;

    const headers = new Headers({
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    });

    const init = {
        method: method,
        headers: headers
    }

    if (!isEmpty(body)) {
        init.body = JSON.stringify(body)
    }

    const response = await fetch(url, init);

    const {data, success, message} = await response.json();
    if (!success) {
        throw new Error(message);
    }

    return {data, success, message};
}